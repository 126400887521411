:root {
    --primary-font-size: 16px; 
}

/**
Breakpoints
**/
@huge: ~'(min-width: 1400px)';
@desktoplarge: ~'(min-width: 1260px)';
@desktop: ~'(min-width: 992px)';
@tablet: ~'(min-width: 768px)';
@mobile: ~'(min-width: 480px)';