h1 {
    font-size: 25px;

    @media @tablet {
        font-size: 40px;
    }

    @media @desktop {
        font-size: 60px;
    }
}

h2 {
    font-size: 16px;

    @media @tablet {
        font-size: 17px;
    }

    @media @desktop {
        font-size: 20px;
    }
}

h3 {
    font-size: 16px;

    @media @tablet {
        font-size: 17px;
    }

    @media @desktop {
        font-size: 20px;
    }
}

h4 {
    font-size: 16px;

    @media @tablet {
        font-size: 17px;
    }

    @media @desktop {
        font-size: 20px;
    }
}

h5 {
    font-size: 16px;

    @media @tablet {
        font-size: 17px;
    }

    @media @desktop {
        font-size: 20px;
    }
}

h6 {
    font-size: 16px;

    @media @tablet {
        font-size: 17px;
    }

    @media @desktop {
        font-size: 20px;
    }
}